import { IonButton, IonCol, IonRow } from "@ionic/react";
import { HcfProfileSource } from "@src/app/dayView/facilityDetails/model";
import { NegotiationStatus } from "@src/app/rateNegotiation/interfaces";
import { NegotiationButton } from "@src/app/rateNegotiation/negotiationButton";
import { isValidUUID } from "@src/app/utils/uuid";
import { BookabilityAttributes } from "@src/appV2/OpenShifts/ShiftAction";
import { ClaimShiftResponse } from "@src/appV2/OpenShifts/ShiftAction/types";
import { Shift } from "@src/lib/interface";

interface Props {
  isRateNegotiationActive: boolean;
  openPreviouslyUnassignedAlert: () => void;
  shift: Shift;
  updateShiftViewRateNegotiation: (shift: Shift) => void;
  workApproved: boolean;
  isLoadingShiftBook: boolean;
  shiftBookability?: BookabilityAttributes;
  hcfProfileSource?: HcfProfileSource;
  onBookShift: (shift: ClaimShiftResponse) => void;
}

export function RateNegotiationButton(props: Props) {
  const {
    shift,
    isRateNegotiationActive,
    openPreviouslyUnassignedAlert,
    isLoadingShiftBook,
    workApproved,
    updateShiftViewRateNegotiation,
    shiftBookability,
    hcfProfileSource,
    onBookShift,
  } = props;
  if (isRateNegotiationActive || shift?.shiftTimeNegotiationActive) {
    const isSyntheticShift = isValidUUID(shift?.shiftId ?? shift._id ?? "");
    if (isSyntheticShift) {
      return (
        <IonRow>
          <IonCol className="ion-text-center">
            <div className="negotiation-btn-container">
              <IonButton
                className="shift-ion-button ion-button negotiation-not-available"
                shape="round"
                data-testid="negotiation-button"
                disabled={true}
                color="dark"
                fill="clear"
              >
                Negotiation not available for this shift
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      );
    }
  }
  if (
    shift?.hasPreviousUnassign &&
    (isRateNegotiationActive || shift?.shiftTimeNegotiationActive)
  ) {
    let cannotProposeButtonText = "Rate/Time";
    if (isRateNegotiationActive && shift?.shiftTimeNegotiationActive) {
      cannotProposeButtonText = "Rate/Time";
    } else if (isRateNegotiationActive) {
      cannotProposeButtonText = "Rate";
    } else if (shift?.shiftTimeNegotiationActive) {
      cannotProposeButtonText = "Time";
    }
    return (
      <IonRow>
        <IonCol className="ion-text-center">
          <div className="negotiation-btn-container">
            <IonButton
              className="shift-ion-button ion-button negotiation-not-available has-previous-unassign"
              shape="round"
              data-testid="hasPreviousUnassign-button"
              fill="clear"
              onClick={() => openPreviouslyUnassignedAlert()}
            >
              {`Cannot Propose ${cannotProposeButtonText}`}
            </IonButton>
          </div>
        </IonCol>
      </IonRow>
    );
  }
  if (
    !(isRateNegotiationActive || shift?.shiftTimeNegotiationActive) ||
    !shift?._id ||
    (isRateNegotiationActive &&
      shift?.rateNegotiation &&
      shift?.rateNegotiation?.status !== NegotiationStatus.OPEN) ||
    (shift?.shiftTimeNegotiationActive &&
      shift?.rateNegotiation &&
      shift?.rateNegotiation?.status !== NegotiationStatus.OPEN)
  ) {
    return null;
  }

  return (
    <IonRow>
      <IonCol className="ion-text-center">
        <NegotiationButton
          shiftDetails={shift}
          workApproved={workApproved}
          updateShiftViewRateNegotiation={updateShiftViewRateNegotiation}
          isLoadingShiftBook={isLoadingShiftBook}
          isRateNegotiationActive={isRateNegotiationActive}
          shiftBookability={shiftBookability}
          hcfProfileSource={hcfProfileSource}
          onBookShift={onBookShift}
        />
      </IonCol>
    </IonRow>
  );
}
