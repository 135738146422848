import { useInterval } from "@src/appV2/lib/utils/useInterval";
import { useEffect, useState } from "react";

interface ICountdown {
  hours: number;
  minutes: number;
  seconds: number;
  callbackFn: Function;
}

type CountDownTimerState = Omit<ICountdown, "callbackFn">;

const CountDownTimer = ({ hours = 0, minutes = 0, seconds = 60, callbackFn }: ICountdown) => {
  const [time, setTime] = useState<CountDownTimerState>({
    hours,
    minutes,
    seconds,
  });

  const reset = () => {
    callbackFn();
  };

  const tick = () => {
    if (time.minutes === 0 && time.seconds === 0) {
      setTime((prevTime) => {
        return {
          hours: prevTime.hours - 1,
          minutes: 59,
          seconds: 59,
        };
      });
    } else if (time.seconds === 0) {
      setTime((prevTime) => {
        return {
          hours: prevTime.hours,
          minutes: prevTime.minutes - 1,
          seconds: 59,
        };
      });
    } else {
      setTime((prevTime) => {
        return {
          hours: prevTime.hours,
          minutes: prevTime.minutes,
          seconds: prevTime.seconds - 1,
        };
      });
    }
  };

  useInterval(
    () => {
      tick();
    },
    // Delay in milliseconds or null to stop it
    // We want this interval to be 1 second
    time.hours >= 0 ? 1000 : undefined
  );

  useEffect(() => {
    if (time.hours < 0) {
      reset();
    }
  }, [time.hours, time.minutes, time.seconds]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>{`${time.hours.toString().padStart(2, "0")} ${time.hours > 1 ? "hrs" : "hr"} ${time.minutes
      .toString()
      .padStart(2, "0")} ${time.minutes > 1 ? "mins" : "min"}`}</>
  );
};

export default CountDownTimer;
